.carousel-indicators {
  display: flex !important;
  margin-top: 10px;
  overflow-x: scroll;
}
.carousel-indicators li {
  margin-right: 10px;
}
.carousel-control-prev,
.carousel-control-next {
  height: 300px;
}
.slick-list {
  height: 300px;
  overflow-y: hidden;
}
.slick-track {
  height: 300px !important;
  overflow-y: hidden;
}
.slick-slider {
  height: 290px;
}
.slick-slider button {
  z-index: 1 !important;
  margin-top: 10px;
}

.carousel-inner {
  height: 410px !important;
}
.auction-end-date {
  bottom: 110px !important;
}

@media only screen and (min-width: 600px) {
  .card-img-bottom.bg-dark.round-0 {
     padding: 1rem !important;
  }
}
@media only screen and (max-width: 600px) {
  .card-img-bottom.bg-dark.round-0 {
     padding: 0.3rem !important;
  }
}