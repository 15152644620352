@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:wght@300;400;500;600;700;800&display=swap');
body{
	background-color:#EFF3FA;
	font-family: 'Montserrat', sans-serif;
	font-weight:400;
	padding-top:93px;
	font-size:18px;
}
.nav-link{
	font-family: 'Bebas Neue', cursive;
	
	padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
}
.bg-dark{
	background-color:#222733 !important;
}

.h-bnr{
	background:#222733 url(../images/newLandingPage/banner-bg.svg) no-repeat right center !important;
	background-size:50% !important;
	color:#fff;
	padding:20px 0px 50px 0px;
	
}
.inner-black-bnr{
	background-size:50% !important;
	min-height:250px;
	background-position:center center !important;
}
.h-bnr .container{
	position:relative;
}
.bnr-small-title{
	font-family: 'Bebas Neue', cursive;
	font-size:18px; 
	text-transform:uppercase;
	color:#3FB0E5;
}
.white-logo{
	display:none;
}
.bnr-big-title{
	font-family: 'Bebas Neue', cursive;
	font-size:67px;
	text-transform:uppercase;
	line-height:62px;
	margin:10px 0px 8px 0px;
}
.h-bnr p{
	color:#8D97A8;
}
.blue-btn{
	background-color:#3FB0E5;
	text-transform:uppercase;
	font-size:14px;
	font-weight:600;
	color:#fff;
}
.black-btn{
	background-color:#000000;
}

.logreg-btn{
	background-color:#3FB0E5;
	color:#fff;
	font-family: 'Bebas Neue', cursive;
	font-size:18px;
}
.logreg-btn:hover{
	color:#fff;
}
.search-bar{
	position:relative;
	width:350px;
	margin:10% 15% 0 0;
	float:right;
}
.search-bar .form-control{
	height:45px;
	background-color:#fff;
	border-radius:30px;
	border:0px;
	padding-left:15px;
	padding-right:15px;
}
.search-btn{
	background:#3FB0E5 url(../images/newLandingPage/icons/Icon%20feather-search.svg) no-repeat center center;
	width:43px;
	height:43px;
	position:absolute;
	top:1px;
	right:1px;
	border-radius:75%;
}
.bnr-car-img{
	position:absolute;
	bottom:-120px;
	right:0px;
	width:60%;
}
.fetured-list-con{
	margin-top:100px;
}
.nav-pills .nav-link{
	background-color:#8B91A0;
	margin-left:10px;
	border-radius:0px;
	padding-top:6px !important;
	padding-bottom:6px !important;
	font-size:18px !important;
	letter-spacing:1px;
	color:#fff;
}
.nav-pills .nav-link.active{
	background-color:#222733;
}
.latest{
	color:#fff;
	font-family: 'Bebas Neue', cursive;
	font-size:16px;
}
/* the slides */
  .slick-slide {
    margin-left:10px;
  }

  /* the parent */
  .slick-list {
    margin-left:-10px;
  }
.f-title{
	font-size:19px;
}
.f-price{
	font-size:22px;
}
.latest hr{
	border:1px #8D97A8 solid;
	opacity:.1;
	margin:2px 0px 10px 0px;
}
.latest .blue-btn{
	font-size:18px;
	letter-spacing:1px;
}
.latest .blue-btn:hover{
	color:#fff;
}
.slick-next, .slick-prev{
	width:40px !important;
	height:40px !important;
	border-radius:75%;
	-webkit-box-shadow: 0px 0px 40px -18px rgba(0,0,0,0.34);
-moz-box-shadow: 0px 0px 40px -18px rgba(0,0,0,0.34);
box-shadow: 0px 0px 40px -18px rgba(0,0,0,0.34);
}
.slick-prev{
	background:#fff url(../images/newLandingPage/pre-arrow.svg) no-repeat center center;
	background-size:12px;
	bottom:-80px;
	top:inherit;
	left:46%;
}
.slick-prev:hover, .slick-prev:focus{
	background:#fff url(../images/newLandingPage/pre-arrow.svg) no-repeat center center;
	background-size:12px;
}
.slick-next{
	background:#fff url(../images/newLandingPage/next-arrow.svg) no-repeat center center;
	background-size:12px;
	bottom:-80px;
	top:inherit;
	right:46%;
}
.slick-next:hover, .slick-next:focus{
	background:#fff url(../images/newLandingPage/next-arrow.svg) no-repeat center center;
	background-size:12px;
}
.slick-prev:before, .slick-next:before{
	display:none;
}
.section-small-title{
	font-family: 'Bebas Neue', cursive;
	font-size:18px; 
	text-transform:uppercase;
	color:#10141B;
	line-height:20px;
}
.section-big-title{
	font-family: 'Bebas Neue', cursive;
	font-size:33px;
	text-transform:uppercase;
	color:#10141B;
	line-height:42px;
}
.best-fleet-con{
	padding-top:30px;
	padding-bottom:30px;
	margin-top:20px;
	font-family: 'Bebas Neue', cursive;
}
.sect-h-txt{
	max-width:700px;
	font-family: 'Montserrat', sans-serif;
}
.fleet-small-title{
	font-family: 'Bebas Neue', cursive;
	font-size:18px; 
	text-transform:uppercase;
	color:#10141B;
	line-height:20px;
}
.fleet-big-title{
	font-family: 'Bebas Neue', cursive;
	font-size:30px;
	text-transform:uppercase;
	color:#10141B;
	
}
.best-fleet-con hr{
	border:1px #8D97A8 solid;
	opacity:.2;
	margin:10px 0px 10px 0px;
}
.fl-title{
	font-size:18px;
}
.fl-price{
	font-size:25px;
	color:#3FB0E5;
}
.about-con{
	background-color:#222733;
	color:#fff;
	padding-top:30px;
	padding-bottom:50px;
}

.about-con .blue-btn{
	font-size:18px;
	padding:15px 25px;
}
.about-con .blue-btn:hover{
	color:#fff;
}
.about-small-title{
	font-family: 'Bebas Neue', cursive;
	font-size:18px; 
	text-transform:uppercase;
	color:#3FB0E5;
	line-height:20px;
}
.about-big-title{
	font-family: 'Bebas Neue', cursive;
	font-size:43px;
	text-transform:uppercase;
	color:#fff;
	
}

.profile-pic{
	width:100px;
	height:100px;
	background-color:#999;
	border-radius:75%;
	overflow:hidden;
}
.profile-small-title{
	font-family: 'Bebas Neue', cursive;
	font-size:18px; 
	text-transform:uppercase;
	color:#fff;
	line-height:20px;
}
.profile-big-title{
	font-family: 'Bebas Neue', cursive;
	font-size:35px;
	text-transform:uppercase;
	color:#fff;
	
}
.wwa-small-title{
	font-family: 'Bebas Neue', cursive;
	font-size:18px; 
	text-transform:uppercase;
	color:#fff;
	line-height:22px !important;
	margin-bottom:10px;
}
.wwa-big-title{
	font-family: 'Bebas Neue', cursive;
	font-size:43px;
	text-transform:uppercase;
	color:#000;
	margin-bottom:25px;
	
}
.about-right{
	background:#3FB0E5 url(../images/newLandingPage/Mask%20Group%203.png) repeat;
	margin-top:-30px;
	color:#000;
	font-size:19px;
	font-weight:300;
	line-height:30px;
	padding-top:25px;
}
.www-icons{
	width:65px;
	height:65px;
	background-color:#fff;
	border-radius:75%;
	overflow:hidden;
}
.partners-con{
	color:#fff;
	margin-top:-150px;
}
.part-bx{
	background-color:#fff;
	height:200px;
}
.partners .slick-prev{
	bottom:inherit;
	left:-60px;
	top:50%;
}
.partners .slick-next{
	bottom:inherit;
	right:-60px;
	top:50%;
}

.partners{
	height: 212px !important;
    margin-bottom: 30px;
}
.partners .slick-track {
	margin-left: -43px !important
}
.partners .slick-slide {
    margin-left: 8px;
}
.auction-con{
	padding-top:30px;
	padding-bottom:30px;
}
.auction-bx{
}
.auction-img{
	background-color:rgba(63,176,229,.2);
	width:120px;
	height:120px;
	border-radius:75%;
	margin:0 auto;
}
.auction-head{
	font-family: 'Bebas Neue', cursive;
	font-size:35px;
	text-transform:uppercase;
	color:#000;;
}


.download-app{
	margin-top:150px;
	margin-bottom:150px;
}
.download-app .container{
	background:#3FB0E5 url(../images/newLandingPage/dotted-path.svg) no-repeat center right;
	border-radius:28px;
	min-height:350px;
	position:relative;
	
}
.download-app-head{
	font-family: 'Bebas Neue', cursive;
	font-size:65px;
	text-transform:uppercase;
	color:#fff;
	line-height:60px;
	margin-left:50px;
	margin-top:70px;
	
}
.btn-row{
	margin-left:40px;
	margin-top:40px;
}
.btn-apple{
	background:#fff url(../images/newLandingPage/icons/apple.svg) no-repeat center left 10px;
	background-size:15px;
	border-radius:0px;
	font-size:14px;
	color:#000;
	text-align:center;
	padding-left:35px;
	padding-right:10px;
}
.btn-android{
	background:#fff url(../images/newLandingPage/icons/android.svg) no-repeat center left 10px;
	background-size:15px;
	border-radius:0px;
	font-size:14px;
	color:#000;
	text-align:center;
	padding-left:35px;
	padding-right:10px;
}
.mobile-img{
	position:absolute;
	top:-100px;
	left:100px;
}
.steps-con{
	padding-bottom:50px;
}
.step-bx{
	background-color:#fff;
	color:#8D97A8;
	margin-bottom:30px;
}
.step-head{
	font-family: 'Bebas Neue', cursive;
	font-size:43px;
	text-transform:uppercase;
	color:#000;
	line-height:10px;
	margin-top:15px;
}
.step-number{
	font-family: 'Bebas Neue', cursive;
	font-size:76px;
	text-transform:uppercase;
	color:#8D97A8;
}
.wwd{
	background:#222733 url(../images/newLandingPage/Path%20158.svg) no-repeat center top 75%;
	padding-top:50px;
	padding-bottom:50px;
}
.wwd-bx{
}
.wwd-bx-txt{
	font-family: 'Bebas Neue', cursive;
	font-size:30px;
	text-transform:uppercase;
	color:#fff;
	line-height:30px;
	margin-top:20px;
}
.testi-con{
	padding-top:40px;
	padding-bottom:40px;
}
.testi-bx{
	background-color:#FFF;
}
.test-profile-img{
	width:57px;
	height:57px;
	border-radius:75%;
	margin:0 auto;
	background-color:#CCC;
	overflow:hidden;
}
.testi-prof-name{
	font-family: 'Bebas Neue', cursive;
	font-size:18px;
	text-transform:uppercase;
	color:#000;
}
.testi-prof-work{
	font-family: 'Bebas Neue', cursive;
	font-size:16px;
	text-transform:uppercase;
	color:#000;
}
.testimonial .slick-prev{
	bottom:inherit;
	top:50%;
	left:-50px;
}
.testimonial .slick-next{
	bottom:inherit;
	top:50%;
	right:-50px;
}
.testimonial .slick-slide {
    margin-left: 0px;
}
.testimonial .slick-track {
    height: 480px !important;
    overflow-y: hidden;
}
.testimonial{
	height: 383px !important;
}
.testimonial .slick-slider {
    height: 383px !important;
}
.testimonial .slick-list {
    height: 495px;
}
.form-con{
	padding-top:20px;
	padding-bottom:40px;
}
.form-con label{
	font-family: 'Bebas Neue', cursive;
	font-size:25px;
	color:#fff;
}
.form-con .form-control{
	background-color:#EFF3FA;
	border-radius:0px;
	border:0px;
	height:61px;
	color:#8D97A8;
	padding-left:20px;
	box-shadow:none;
	
}
.form-con textarea{
	min-height:150px;
}
.send-btn{
	font-family: 'Bebas Neue', cursive;
	width:190px;
	display:block;
	padding:12px 25px 10px 25px;
	font-size:25px;
	letter-spacing:1px;
	font-weight:500;
}
.send-btn:hover{
	color:#fff;
}
.gray-btn{
	font-family: 'Bebas Neue', cursive;
	width:190px;
	display:block;
	padding:12px 25px 10px 25px;
	font-size:25px;
	letter-spacing:1px;
	font-weight:500;
	background-color:#D0D0D0;
	color:#000;
}
.gray-btn:hover{
	color:#000;
}
.com-btn{
	font-family: 'Bebas Neue', cursive;
	width:100%;
	margin:0 auto;
	display:block;
	padding:12px 25px 8px 25px;
	font-size:22px;
	letter-spacing:1px;
	font-weight:300;
}
.com-btn:hover{
	color:#fff;
}
.footer-con{
	background:#222733 url(../images/newLandingPage/footer-bg.svg) no-repeat center top 50px;
	background-size:100%;
	padding-top:200px;
}

.f-tline{
	border-top:1px #666666 solid;
}
.f-logo{
	width:300px;
	height:120px;
	margin-top:-60px;
}
.f-left{
	color:#fff;
	font-size:35px;
	padding-right:40px;
}
.f-mail{
	color:#3FB0E5;
	text-decoration:none;
	display:block;
}
.f-phone{
	color:#3FB0E5;
	text-decoration:none;
	display:block;
}
.f-right-row{
	border-bottom:1px #666666 solid;
	padding-bottom:20px;
	color:#fff;
	padding-top:20px;
}
.f-right-row ul{
	padding:0px;
	margin:0px;
	list-style:none;
}
.f-right-row li a{
	display:block;
	color:#fff;
	margin:5px 0px;
	text-decoration:none;
}
.fbtm-top{
	border-top:1px #666666 solid;
	color:#fff;
	padding-top:20px;
	padding-bottom:20px;
	font-size:14px;
}
.fbtm-top a{
	display:inline-block;
	text-decoration:none;
	color:#fff;
	margin:0 0px 0 20px;
	font-size:14px !important;
	text-align:right;
}
.testimonial{
	margin:80px 0px;
}
.slick-center .testi-bx{
	background-color:#3FB0E5;
	color:#fff;
	position:relative;
	
}
.testimonial .slick-center{
	background-color:#3FB0E5;
	position:relative;
	padding:50px 0px;
	margin-top:-50px;
}
.testimonial .slick-track {
	padding-top:50px;
}
.slick-center .testi-bx::before{
	content:'';
	position:absolute;
	width:30px;
	height:30px;
	top:20px;
	left:0px;
	right:0px;
	margin:0 auto;
	background:url(../images/newLandingPage/white-quote.svg) no-repeat center center;
}
.slick-list{
	overflow-y:visible !important;
}
.slick-center .testi-prof-name{color:#fff;}
.slick-center .testi-prof-work{color:#fff;}

.inner-page{
	position: relative;
	min-height:700px;
	color:#8D97A8;
	background-color:#FFF;
}
.inner-page a{
	color:#3FB0E5;
	text-decoration:none;
}
.inner-page .container{
	position:relative;
	z-index:1000;
}
.in-bg-left {
    background:#3FB0E5; 
    left: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    width: 50%;
}
.in-bg-right {
    right: 0;
    top: 0;
    bottom: 0;
    background: #fff; 
    position: absolute;
    width: 50%;
}
.in-left-head{
	font-family: 'Bebas Neue', cursive;
	font-size:107px;
	color:#fff;
	text-transform:uppercase;
	line-height:82px;
}
.in-left-head span{
	font-size:81px;
	display:block;
}
.inner-left{
	background:#3FB0E5;
	padding-top:45%; 
}
.inner-right{
	padding-top:50px;
	padding-left:50px;
}
.in-right-head{
	font-family: 'Bebas Neue', cursive;
	font-size:40px;
	color:#000;
	text-transform:uppercase;
	margin-bottom:30px;
}
.ph-num{
	color:#8D97A8;
	padding-bottom:20px;
}
.or-con{
	border-top:1px #8D97A8 solid;
	position:relative;
	margin:40px 0px;
}
.or-txt{
	background-color:#FFF;
	width:200px;
	padding:5px 0px;
	margin:-20px auto 0 auto;
	font-size:18px;
	color:#8D97A8;
	text-align:center;
}
.nhelp{
	padding-left:15px;
	background:url(../images/newLandingPage/Polygon%202.png) no-repeat left center;
	display:inline-block;
}
.c-acc{
	padding-top:60%;
}
.gin-t{
	background-color:#EFF3FA;
	padding-top:50px;
}
.gin-t label{
	color:#000;
}
.gin-t .form-control{
	background-color:#DDECF9;
}
.profile-img{
	width:170px;
	height:170px;
	background-color:#A4C3E5;
	border-radius:75%;
	margin-top:25px;
}
.profile-img img{
	margin-top:40px;
}
.img-upload{
	background-color:#DDECF9;
	padding:20px;
	cursor:pointer;
}
.upload-icon{
	width:65px;
	height:65px;
	margin:0 auto ;
	background:#FFF url(../images/newLandingPage/icons/Icon-upload-cloud.svg) no-repeat center center;
	border-radius:75%;
}
.upload-txt{
	font-family: 'Bebas Neue', cursive;
	color:#000;
	text-align:center;
	margin-top:20px;
	font-size:18px;
}
.file-upload{
	display:none;
}
.au-otp-con{
	max-width:600px;
}
.otp-txt{
	font-size:30px;
	font-weight:800;
	padding-left:0px !important;
}
.sel-fld{
	font-family: 'Bebas Neue', cursive;
	background:url(../images/newLandingPage/icons/Icon%20ionic-ios-arrow-down.svg) no-repeat center right 20px;
}
.alert-blue{
	background:#DDECF9 url(../images/newLandingPage/icons/Icon%20awesome-file-alt.svg) no-repeat center left 20px;
	font-family: 'Bebas Neue', cursive;
	text-shadow:none;
	color:#000;
	letter-spacing:1px;
	border:0px;
	padding-left:60px;
}
.alble-close{
	box-shadow:none !important;
	font-size:14px !important;
	outline:none !important;
	margin-top:3px;
}
.au-dup-con{
	max-width:940px;
}
.price-con{
	padding-top:30px;
	padding-bottom:30px;
}
.price-bx{
	background-color:#FFF;
	padding:30px;
	color:#8D97A8;
	min-height:500px;
}
.midp-bx{
	background-color:#222733;
	min-height:550px;
	margin-top:-25px;
	color:#fff;
}
.pack-title{
	color:#000;
	font-size:24px;
	font-family: 'Bebas Neue', cursive;
}
.midp-bx .pack-title{
	color:#3FB0E5;
}
.pack-price{
	color:#000;
	font-size:56px;
	font-family: 'Bebas Neue', cursive;
	margin-top:-15px;
}
.midp-bx .pack-price{
	color:#fff;
}
.pack-price span{
	font-size:16px;
	color:#8D97A8;
}
.pack-dtls{
	border-top:1px #D0DAEC solid;
	padding-top:15px;
	padding-bottom:15px;
}
.pack-dtls ul{
	padding:0px;
	margin:0px;
	list-style:none;
}
.pack-dtls li{
	padding:5px 0px 5px 30px;
	background:url(../images/newLandingPage/icons/Icon%20ionic-ios-checkmark.svg) no-repeat center left;
}
.pricbx-btn{
	width:100%;
	font-size:27px;
}
.form-check{
	margin-bottom:15px;
}
.form-check-input{
	height:29px;
	width:29px;
}
.form-check-label{
	font-family: 'Bebas Neue', cursive;
	padding-top:8px;
	padding-left:15px;
}
.alert-bx{
	font-family: 'Bebas Neue', cursive;
	background-color:#D0E5DF;
	border:1px #55D038 solid !important;
	color:#2F9D15;
}
.filter-wrap{
}
.filter-con{
	background-color:#FFF;
	height:165px;
	padding:20px;
}
.filter-title{
	font-family: 'Bebas Neue', cursive;
	font-size:25px;
	color:#000;
	background:url(../images/newLandingPage/icons/filter-up.svg) no-repeat right top 5px;
	width:90px;
	cursor:pointer;
}
.filter-title.active{
	background:url(../images/newLandingPage/icons/filter-down.svg) no-repeat right top 5px;
}
.filter-con .form-control{
	background:#EFF3FA url(../images/newLandingPage/icons/Icon%20ionic-ios-arrow-down-gray.svg) no-repeat center right 15px;
	color:#8D97A8;
	font-size:16px;
	height:48px;
	box-shadow:none;
	border:0px;
}
.filter-apply{
	font-family: 'Bebas Neue', cursive;
	font-size:20px;
	color:#fff;
	letter-spacing:1.5px;
	font-weight:500;
}
.filter-apply:hover{
	color:#fff;
}
.cancel-filter{
	font-family: 'Bebas Neue', cursive;
	background-color:#FFF;
	color:#3FB0E5;
	font-size:20px;
	letter-spacing:1.5px;
	font-weight:500;
	border:1px #3FB0E5 solid;
}
.cancel-filter:hover{
	color:#3FB0E5;
}
.list-wrap{
	padding:20px 0px;
}
.list-bx{
	background-color:#FFF;
}
.list-bx-img{
	position:relative;
}
.list-bx-con{
	padding:15px;
}
.list-bx-title {
    color: #000;
    font-size: 24px;
    font-family: 'Bebas Neue', cursive;
}
.list-bx-price {
    color: #000;
    font-size: 24px;
    font-family: 'Bebas Neue', cursive;
}
.list-bx-status{
	border-radius:20px;
	background-color:#3FB0E5;
	color:#fff;
	font-size:13px;
	padding:5px 15px;
}
.list-bx-dtls{
	border-top:1px #D0DAEC solid;
	padding-top:15px;
	padding-bottom:15px;
}
.list-bx-dtls ul{
	padding:0px;
	margin:0px;
	list-style:none;
}
.list-bx-dtls li{
	padding:0px 0px 5px 30px;
	background:url(../images/newLandingPage/icons/Icon%20ionic-ios-checkmark.svg) no-repeat top 5px left;
	color:#000;
	font-size:15px;
}
.list-bx-dtls li span{
	color:#8D97A8;
	display:block;
}
.auction-end-date{
	background-color:rgba(0,0,0,.6);
	position:absolute;
	bottom:0px;
	right:0px;
	width:75%;
	padding:5px;
}
.auction-end-date-title {
    color: #fff;
    font-size: 15px;
    font-family: 'Bebas Neue', cursive;
}
.auction-end-date-dtls{
	font-family: 'Bebas Neue', cursive;
}
.auction-end-date-dtls ul{
	padding:0px;
	margin:0px;
	list-style:none;
}
.auction-end-date-dtls li{
	padding:4px 5px 0px 5px;
	color:#000;
	font-size:15px;
	background-color:#fff;
	float:left;
	margin:0px 2px;
}
.auction-end-date-dtls li.space{
	background-color:transparent;
	padding:3px;
	margin:0px 1px;
}
.auction-dtls{
	padding-top:40px;
}
.auction-dtls .carousel-indicators {
	bottom:0px;
	margin-right:0px;
	margin-left:0px;
	position:static;
	display:inline;
}
.auction-dtls .carousel-indicators button {
	width: 23.9% !important;
	margin:0px 4px 0px 0px;
}
.auction-dtls .carousel-indicators button:last-child{
	margin-right:0px;
}
.auction-dtls .carousel-indicators [data-bs-target]{
	text-indent:0px;
}
.auction-dtls .carousel-inner{
	position:relative;
}
.auction-dtls-date{
	z-index:1000;
	padding:15px;
}
.auction-dtls-date .auction-end-date-title{
	font-size:25px;
	float:left;
	text-align:right;
	display: inline-block;
    padding-top: 12px;
}
.auction-dtls-date .auction-end-date-dtls{
	float:right;
}
.auction-dtls-date .auction-end-date-dtls li{
	font-size:33px;
	padding: 4px 8px 0px 8px;
	line-height:33px;
	margin-top:9px;
}
.auction-dtls .carousel-control-next-icon, .auction-dtls .carousel-control-prev-icon{
	background-color:#000;
	background-size:25px;
}
.auction-dtls-bx{
	background-color:#FFF;
	padding:20px;
	color:#222733;
}
.auction-dtls-bx-title {
    color: #222733;
    font-size: 30px;
    font-family: 'Bebas Neue', cursive;
}

.auction-dtls-bx .nav-link{
	background-color:#9ED7F1;
	border-radius:25px;
	padding-top:10px !important;
	padding-bottom:10px !important;
	font-size:18px !important;
}
.auction-dtls-bx .nav-link.active{
	background-color:#3FB0E5;
}
.auction-dtls-bx .accordion-button::after {
      background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
      transform: scale(.7) !important;
    }
    .auction-dtls-bx .accordion-button:not(.collapsed)::after {
      background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M0 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
    }
.auction-dtls-bx .accordion-item{
	border-top:0px;
	border-left:0px;
	border-right:0px;
	border-color:#E0E0E0;
}
.auction-dtls-bx .accordion-button{
	box-shadow:none !important;
	font-size:22px;
	font-family: 'Bebas Neue', cursive;
	color:#000000;
}
.auction-dtls-bx .accordion-button:not(.collapsed){
	color:#000000;
}
.faq-bx{
}
.faq-bx .accordion-button::after {
	background:url(../images/newLandingPage/icons/arrow-dropup.png) no-repeat left center;
	margin-right:auto !important;
	margin-left:0px !important;
	position:absolute;
	left:0px;
    }
.faq-bx .accordion-button:not(.collapsed)::after {
      background:url(../images/newLandingPage/icons/arrow-dropdown.png) no-repeat left center;
	  margin-right:auto !important;
	  margin-left:0px !important;
	  position:absolute;
	left:0px;
	transform: rotate(0deg);
    }
.faq-bx .accordion-button{
	box-shadow:none !important;
	font-size:25px;
	font-family: 'Bebas Neue', cursive;
	color:#3FB0E5;
}
.faq-bx .accordion-item{
	border:0px;
	background-color:transparent;
}
.faq-bx .accordion-button{
	background-color:transparent;
	padding-left:30px;
	padding-bottom:0px;
}
.faq-bx .card-body{
	padding-left:30px;
}
.faq-bx .card-body p{
	margin-bottom:0px;
}
.pdtls{
	color:#707070;
}
.pdtls-price{
	font-size:40px;
}
.pdtls-price span{
	font-size:25px;
	display:inline-block;
	margin-right:20px;
}
.pdtls-title{
	font-size:32px;
}
.pdtls-label{
	font-size:16px;
	padding-top:7px;
	padding-left:10px;
}
.mp-btn{
	width:50px;
	height:50px;
	background-color:#3FB0E5;
	color:#fff !important;
	display:inline-block;
	font-size:50px;
	text-align:center;
	line-height:42px;
}
.plus-btn{
	line-height:48px;
	font-size:40px;
}
.mp-txtfld{
	width:100px;
	height:50px;
	font-family: 'Bebas Neue', cursive;
	color:#000;
	font-size:30px;
	text-align:center;
	display:inline-block;
	border-top:1px #3FB0E5 solid;
	border-bottom:1px #3FB0E5 solid;
	line-height:57px;
}
.bid-list{
}
.bid-title{
	font-family: 'Bebas Neue', cursive;
	font-size:30px;
	color:#000;
}
.bid-con{
	background-color:#FFF;
	padding:15px;
}
.search-fld{
	background:#EFF3FA url(../images/newLandingPage/icons/Icon%20ionic-md-search.svg) no-repeat left 10px center !important;
	padding-left:40px;
}

.bid-row{
	font-family: 'Bebas Neue', cursive;
	color:#8D97A8;
	font-size:18px;
	padding-top:10px;
	padding-bottom:10px;
}
.altr-color{
	background-color:#EFF3FA;
}
.sold-color{
	background-color:rgba(230,7,7,.10);
}
.win-txt{
	color:#3FB0E5;
}
.loss-txt{
	color:#FF0000;
}
.bid-select{
	background:#8D97A8 url(../images/newLandingPage/icons/Icon%20ionic-ios-arrow-down-white.svg) no-repeat right 15px center !important;
	border:0px;
	font-family: 'Montserrat', sans-serif;
	font-size:12px;
	color:#fff;
	height:40px;
}
.s-car-form{}
.s-car-form label{
	font-family: 'Bebas Neue', cursive;
}
.s-car-form .form-control{
	height:45px;
	border:0px;
	border-radius:0px;
}
.radio-btn{
	border-radius:0px;
	border:1px #C8D0DC solid;
	padding:15px 35px;
}


.rbtn-group input {
 display: none;
}

.rbtn-group input:checked + label{
    background-color: #B1DFF5;
}
.rupee{
	background:url(../images/newLandingPage/icons/Group%20484.svg) no-repeat left 20px center;
	background-size:30px;
	padding-left:55px;
}
.photo-upload{
	border:1px #8D97A8 solid;
	background:url(../images/newLandingPage/icons/Group%20487.svg) no-repeat center center;
	min-height:200px;
	margin-bottom:25px;
	text-decoration:none;
	cursor:pointer;
	position:relative;
	text-indent:-9999px;
	font-family: 'Bebas Neue', cursive;
	
}

.photo-upload:hover{
	display:block;
	background:#EFF3FA url(../images/newLandingPage/icons/Icon%20feather-camera.svg) no-repeat center top 50px;
	border-color:#000;
	min-height:200px;
	padding-top:110px;
	text-indent:inherit;
	font-size:25px !important;
	color:#000;
}
.filter-left{}
.filter-left .accordion-item{
	border:0px;
	background-color:transparent;
	border-bottom:1px #666666 solid;
}
.filter-left .accordion-button{
	background-color:transparent;
	padding-right:0px;
	outline:none;
	box-shadow:none;
	color:#000 !important;
	font-size:25px;
}
.filter-left .accordion-header{
	font-family: 'Bebas Neue', cursive;
}

.filter-left .accordion-button:not(.collapsed)::after{
	background-color:#3FB0E5;
	width:30px;
	height:30px;
	background-position:center center;
	background-image:url(../images/newLandingPage/icons/Icon%20ionic-ios-arrow-up.svg) !important;
	border-radius:75%;
	background-size:12px !important;
}
.filter-left .accordion-button::after{
	background-color:#3FB0E5;
	width:30px;
	height:30px;
	background-position:center center;
	background-image:url(../images/newLandingPage/icons/Icon%20ionic-ios-arrow-up.svg) !important;
	border-radius:75%;
	background-size:12px !important;
}
.cat-list ul{
	padding:0px;
	margin:0px 0px 0px 0px;
	list-style:none;
	color:#000;
	}
.cat-list li{
	margin-bottom:10px;
}
.parent-li{
	position:relative;
	padding-left:50px;
}
.parent-li::before{
	position:absolute;
	width:40px;
	height:1px;
	background-color:#333;
	content:'';
	position:absolute;
	left:0px;
	top:12px;
}
.child-li{
	position:relative;
	padding-left:50px;
}
.child-li::before{
	position:absolute;
	width:30px;
	height:1px;
	background-color:#333;
	content:'';
	position:absolute;
	left:10px;
	top:12px;
}
.end-child{
	padding-left:10px;
	margin-left:40px;
}
.cat-list li.active{
	background-color:rgba(63,176,229,.5);
	padding:8px 10px 8px 10px;
}

.location-list{
	color:#000;
}
.location-list ul{
	list-style:none;
}
.location-list li.active{
	font-weight:600;
}
.location-list li{
	padding-bottom:10px;
}
.brand-search-bar{
	background:#DDECF9 url(../images/newLandingPage/icons/Icon%20feather-search-black.svg) no-repeat right 10px center ;
	padding:8px 20px 8px 8px;
	height:45px;
}
.brand-check-list label{
	font-family:inherit;
}
.specification-bx{
	background-color:rgba(63,176,229,.2);
	padding:20px;
	color:#000;
}
.contact-form{
	background-color:#FFF;
	padding:15px;
	color:#000;
}
.contact-form .form-control{
	border-radius:0px;
	border:0px;
	height:45px;
}
.contact-form label{
	font-family: 'Bebas Neue', cursive;
}
.contact-head{
	font-family: 'Bebas Neue', cursive;
	font-size:25px;
}
.whatsapp-btn{
	position:relative;
	display:inline-block;
}
.whatsapp-btn::before{
	content:'';
	background:#3FB0E5 url(../images/newLandingPage/icons/Icon%20ionic-logo-whatsapp.svg) no-repeat left 32% center;
	background-size:70%;
	width:40px;
	height:40px;
	position:absolute;
	top:-5px;
	left:-40px; 
}
.filter-btn{
	display:none;
}
.filter-overlay{
	display:none;
}
.fliter-close-btn{
	display:none;
}

/*header after login*/
.head-myaccount .nav-link{
	color:#000 !important;
}
.hm-top{
	width:100% !important;
}
.hm-top .nav-link{
	color:#000 !important;
	padding:10px 13px 10px 13px !important;
}
.head-myaccount .form-control{
	font-family: 'Bebas Neue', cursive;
	background-color:#F2F3F5;
	height:36px;
	text-transform:uppercase;
	font-size:14px;
	color:#707070;
	border:0px;
	font-weight:600;
	border-radius:6px;
	letter-spacing:.1em;
	box-shadow:none;
}
.head-myaccount .form-control::placeholder{
	font-family: 'Bebas Neue', cursive;
	color:#999;
	font-weight:500;
}
.head-top-search{
	background:#F2F3F5 url(../images/newLandingPage/icons/Icon%20feather-search-blue.svg) no-repeat center right 10px;
}
.head-top-select{
	background:#F2F3F5 url(../images/newLandingPage/icons/Polygon%202.svg) no-repeat center right 10px;
}
.mc-hello{
	
	text-decoration:none;
	color:#000;
	font-size:12px;
	font-weight:600;
}
.mc-call{
	
	text-decoration:none;
	color:#000;
	font-size:14px;
	font-weight:600;
}
.mc-link{
	font-family: 'Bebas Neue', cursive;
	text-decoration:none;
	color:#000;
	font-size:16px;
}
.call-num{
	font-size:18px;
	color:#3FB0E5;
	font-weight:600;
	text-decoration:none;
}
.hm-btm{
	width:100%;
}
.hm-btm-subhead{
	font-family: 'Bebas Neue', cursive;
	font-size:18px;
	color:#B4B4B4;
	text-transform:uppercase;
	font-weight:500;
	letter-spacing:.1em;
}
.home-banner-con{
	background:url(../images/newLandingPage/banner/Group369.jpg) no-repeat center center;
	background-size:cover;
	min-height:550px;
}
.home-search-box{
	background-color:#fff;
	padding:10px 25px;
}
.home-sbx-head{
	font-family: 'Bebas Neue', cursive;
	font-size:35px;
	color:#000;
	text-transform:uppercase;
	font-weight:500;
	letter-spacing:.01em;
}
.home-search-box .nav-link{
	background-color:#FFF;
	border:1px #333333 solid;
	color:#000;
	margin-left:0px;
	margin-right:10px;
	padding-right:44px !important;
	padding-left:44px !important;
	
}
.home-search-box .nav-link.active:after{
	content:" ";
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 10px solid #222733;
    position:absolute;
    bottom:-10px;
    margin-left:10px;
	margin:0 auto;
	left:0px;
	right:0px;
}
.home-search-box .form-check-input{
	width:15px;
	height:15px;
	margin-top:8px;
}
.home-search-box .form-check-label{
	font-family: 'Montserrat', sans-serif;
	padding:0px;
	font-size:13px;
}
.home-search-box .form-control{
	border-color:#707070;
	border-radius:0px;
	font-size:12px;
	height:46px;
}
.home-search-box select{
	background:url(../images/newLandingPage/icons/Polygon%207-black.svg) no-repeat center right 10px;
}
.btn-search{
	color:#fff;
	font-size:14px;
	background-color:#3FB0E5;
	width:100%;
	font-weight:600;
	height:45px;
}
.btn-search:hover{
	background-color:#319BCC;
	color:#fff;
}
.home-section-tab{
	background-color:#F3F6FC;
	margin-bottom:10px;
}
.home-section-tab h2{
	font-family: 'Bebas Neue', cursive;
	font-size:27px;
	letter-spacing:.01em;
}
.home-section-tab .nav-link{
	font-family: 'Montserrat', sans-serif;
	background-color:transparent;
	color:#000;
	font-weight:500;
	margin-left:0px;
	margin-right:10px;
}
.home-section-tab .nav-link.active{
	background-color:transparent;
	color:#3FB0E5;
	border-bottom:3px #3FB0E5 solid;
	font-weight:600;
}
.home-section-tab .v-name{
	font-size:12px;
	font-weight:500;
}
.home-section-tab .v-price{
	font-family: 'Bebas Neue', cursive;
	font-size:20px;
	font-weight:500;
}
.home-section-tab .v-btn{
	border-color:#3FB0E5;
	border-radius:0px;
	font-size:12px;
	font-weight:600;
	color:#3FB0E5;
	width:100%;
}
.home-section-tab .v-btn:hover{
	background-color:#3FB0E5;
	color:#fff;
}
.gray-line{
	background-color:#999;
}
.view-all-link{
	color:#3FB0E5;
	font-size:14px;
	font-weight:600;
}
.ev-tag{
	position:absolute;
	bottom:20px;
	left:20px;
	background:#FFF url(../images/newLandingPage/icons/Iconmap-electrician.svg) no-repeat left 8px center;
	padding:4px 8px 4px 22px;
	font-size:13px;
	font-weight:500;
}
#desk-myaccount{
	display:block;
}
#mob-myaccount{
	display:none;
}
@media only screen and (max-width: 600px) {
	.nav-pills .nav-link{
		font-size:14px !important;
		margin-left:5px !important;
		padding-left:10px !important;
		padding-right:10px !important;
	}
	.float-end{
		float:none !important;
	}
	.slick-next{right:40%;}
	.slick-prev{left:35%;}
	.about-right{
		margin-top:30px;
	}
	
	.partners .slick-prev {
  left: -10px;
  z-index: 100;
  	-webkit-box-shadow: 0px 0px 40px -12px rgba(0,0,0,0.5);
-moz-box-shadow: 0px 0px 40px -12px rgba(0,0,0,0.5);
box-shadow: 0px 0px 40px -12px rgba(0,0,0,0.5);
}
.partners .slick-next {
  right: -10px;
  z-index: 100;
  	-webkit-box-shadow: 0px 0px 40px -12px rgba(0,0,0,0.5);
-moz-box-shadow: 0px 0px 40px -12px rgba(0,0,0,0.5);
box-shadow: 0px 0px 40px -12px rgba(0,0,0,0.5);
}
.mobile-img{
	position:static;
	width:300px;
	margin:0 auto;
	margin-top:20px;
	margin-bottom:60px;
}

.btn-row{
	margin-left:15px;
	margin-top:10px;
	margin-bottom:30px;
}
.download-app{
	margin:50px 0px 40px 0px;
}
.step-bx{
	margin-bottom:20px;
}
/* .testimonial{
	margin:30px 0px 10px 0px;
	} */
.testimonial .slick-prev {
  left: -10px;
  z-index: 100;
  	-webkit-box-shadow: 0px 0px 40px -12px rgba(0,0,0,0.5);
-moz-box-shadow: 0px 0px 40px -12px rgba(0,0,0,0.5);
box-shadow: 0px 0px 40px -12px rgba(0,0,0,0.5);
}
.testimonial .slick-next {
  right: -10px;
  z-index: 100;
  	-webkit-box-shadow: 0px 0px 40px -12px rgba(0,0,0,0.5);
-moz-box-shadow: 0px 0px 40px -12px rgba(0,0,0,0.5);
box-shadow: 0px 0px 40px -12px rgba(0,0,0,0.5);
}

.footer-con{
	background-position:center top 40%;
	
}
.navbar-light .navbar-toggler{
  border:none !important;
  outline:none !important;
  box-shadow:none !important;
} 
.navbar-light .navbar-toggler:focus{
  border:none;
  outline:none !important;
  box-shadow:none !important;
} 
.form-con{
	padding-bottom:70px;
}
.download-app-head{
	margin-left:25px;
}
.in-bg-left {
	display:none;
}
.in-bg-right {
	display:none;
}
.inner-page .container{
	padding-left:0px;
	padding-right:0px;
}
.inner-left{
	padding:40px 30px 30px 30px;
}
.in-left-head{
	font-size:89px;
	line-height:70px;
}
.in-left-head span{
	font-size:68px;
}
.inner-right{
	padding:30px;
}
.footer-con{
	padding-top:90px;
}
.gin-con{
	padding-left:15px !important;
	padding-right:15px !important;
}
.form-check{
	margin-left:15px;
}
.auction-dtls{
	padding-top:20px;
}
.auction-dtls-date .auction-end-date-dtls li{
	font-size: 22px;
	line-height:22px;
}
.auction-dtls-date .auction-end-date-title{
	padding-top:0px;
	line-height:22px;
}
.auction-dtls-date li.space{
	padding:2px;
}
.carousel{
	height:180px;
	margin-bottom:70px;
}
.auction-dtls .carousel-indicators button{
	width:23% !important;
}
.faq-bx{
	padding:0px;
}
.pdtls{
	padding:0px;
}
.inner-page .container{
	padding-left:15px;
	padding-right:15px;
}
.auction-dtls-bx .nav-link{
	font-size:11px !important;
}
.auction-dtls-date{
	padding-left:10px;
	padding-right:10px;
}
.carousel-control-next{
	z-index:1000;}
.mob-col-left{
	float:left;
	width:50%;
	line-height:37px;
}
.mob-col-right{
	float:right;
	width:50%;
}
.mob-altr-color{
	background-color:#EFF3FA;
	padding-top:8px;
}
.mob-altr-color-white{
	background-color:#fff;
	padding-top:8px;
}
.bid-title{
	display:block !important;
}
.filter-left{
	display:none;
	background-color:#FFF;
	position:fixed;
	left:0px;
	top:90px;
	height:100%;
	width:90%;
	z-index:10000;
	padding-right:20px;
	
}
.filter-inner{
	overflow-y: scroll;
	position:absolute;
	width:100%;
	height:100%;
	padding-right:20px;
	padding-bottom:100px;
}
.filter-overlay{
	position:fixed;
	left:0px;
	top:90px;
	height:100%;
	width:100%;
	z-index:999;
	background-color:rgba(0,0,0,.9);
}
.filter-btn{
	display:inline-block;
	outline:none !important;
	box-shadow:none !important;
	color:#000 !important;
	background:url(../images/newLandingPage/icons/setting.png) no-repeat center left;
	background-size:20px;
	padding-left:25px;
}
.fliter-close-btn{
	position:absolute;
	right:-35px;
	top:10px;
	width:30px;
	height:30px;
	background:url(../images/newLandingPage/icons/close-button.png) no-repeat center center;
	background-size:30px;
	cursor:pointer;
	display:inline-block;
}
.gin-t{
	padding-top:10px;
}
.search-bar {
  position: relative;
  width: 100%;
  margin: 20px auto 0px auto;
  float: right;
}
.bnr-car-img{
	width:95%;
	bottom:-140px;
}
.navbar-toggler-icon{
	background-color:#1fa3e0;
	padding:3px;
	border-radius:3px;
	background-size:22px;
}

.hm-top .nav-item{
	border-bottom:1px #CCCCCC solid;
}
.hm-top .navbar-nav{
	margin-bottom:10px;
}
#desk-myaccount{
	display:none;
}
#mob-myaccount{
	display:block;
	position:absolute;
	top:14px;
	right:70px;
}
#mob-callus{display:none;}
.dropdown-menu[data-bs-popper]{
	left:inherit;
	right:0px;
}
.hm-top{
	border:none !important;
}
.hm-btm .nav-link {
	padding-left:10px !important;
	padding-top:3px !important;
	padding-bottom:3px !important;
	border-bottom:1px #CCCCCC solid;
}
.about-right{
	position:static !important;
}
}